import './scss/main.scss';
import logo from './assets/img/black-label.png';

const navbar = document.getElementById('navbar-logo');
navbar.src = logo;

const path = location.pathname.split("/")[1];

Array.from(document.querySelectorAll('a'))
    .filter(node => node.pathname.split("/")[1] == path)
    .forEach(node => node.classList.add('--active'));
